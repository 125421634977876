import apiRequest from "../../middlewares/api-request";
const signup = {
  state: {},
  mutations: {},
  actions: {
    async GET_PASS({}, phone) {
      return await apiRequest.function("POST", `signup/`, null, null, {
        phone,
      });
    },
  },
  getters: {},
};

export default signup;
