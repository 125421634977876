<template>
  <div class="lk-layout lk">
    <v-header></v-header>
    <div class="lk__layout-container">
      <router-view />
    </div>
    <v-footer></v-footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "lk-layout",
  computed: {
    ...mapGetters([]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
  watch: {},
  async mounted() {
    const autoLoginToken = this.$route?.query?.bearer || undefined;
    if (autoLoginToken !== undefined) {
      localStorage.setItem("multipolis_token", autoLoginToken);
      this.$store.commit("SET_TOKEN", autoLoginToken);
      this.$router.push({ path: this.$route?.path });
    }
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.lk-layout {
}
.lk {
  display: flex;
  flex-direction: column;
  min-height: 74vh;
  /* .lk__layout-container */

  &__layout-container {
    max-width: 1160px;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 auto;
    padding: 30px 10px;
  }
  /* .lk__links */

  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  /* .lk__link */

  &__link {
    padding: 10px;
    color: $accent;
    border: 1px solid $accent;
    transition: 0.5s;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 20px;
    font-weight: 500;
    margin-bottom: 12px;
    &:last-child {
      margin-right: 0;
    }
    &.router-link-exact-active {
      background-color: $accent;
      color: $white;
    }
  }

  /* .lk__banner */

  &__banner {
    margin-top: 25px;
    max-width: 500px;
    background: linear-gradient(
      94.8deg,
      #95e0dd 0%,
      #55c998 34.9%,
      #3fe4c6 100%
    );
    border-radius: 15px;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 100px;
  }

  /* .lk__banner-title */

  &__banner-title {
    font-weight: 500;
    font-size: 24px;
    color: $white;
    margin: 0;
  }

  /* .dashboard__banner-icon */

  &__banner-icon {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  }
}

@media (max-width: 575px) {
  .dashboard-layout {
  }
  .dashboard {
    /* .dashboard__container */

    &__container {
    }

    /* .dashboard__links */

    &__links {
    }

    /* .dashboard__link */

    &__link {
    }

    /* .dashboard__banner */

    &__banner {
    }

    /* .dashboard__banner-title */

    &__banner-title {
      font-size: 20px;
      max-width: 50%;
    }

    /* .dashboard__banner-icon */

    &__banner-icon {
    }
  }
}
</style>
