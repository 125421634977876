<template>
  <div class="default-layout default">
    <v-header></v-header>
    <router-view />
    <v-footer></v-footer>
  </div>
</template>
<script>
export default {
  name: "default-layout",
};
</script>
<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: 1 1 auto;
  }
}
.default {
}
</style>
