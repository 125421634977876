<template>
  <div class="slider" @click="toggle">
    <div class="slider-background" :class="{ active: modelValue }"></div>
    <div class="slider-thumb" :class="{ active: modelValue }"></div>
    <span class="slider-text"><slot /></span>
  </div>
</template>
<script>
import { gsap } from "gsap";

export default {
  name: "v-toggle",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.setupAnimations();
    this.playAnimations();
  },
  methods: {
    toggle() {
      this.$emit("update:modelValue", !this.modelValue);
    },
    setupAnimations() {
      this.sliderAnimation = gsap.timeline({ paused: true });
      this.sliderAnimation.to(".slider-thumb", {
        x: 18,
        duration: 0.2,
        ease: "power1.inOut",
      });

      this.backgroundAnimation = gsap.timeline({ paused: true });
      this.backgroundAnimation.to(".slider-background", {
        backgroundColor: "#1ABC9C",
        duration: 0.2,
      });
    },
    playAnimations() {
      if (this.modelValue) {
        this.sliderAnimation?.play();
        this.backgroundAnimation?.play();
      } else {
        this.sliderAnimation?.reverse();
        this.backgroundAnimation?.reverse();
      }
    },
  },
  watch: {
    modelValue(newVal) {
      this.playAnimations();
    },
  },
};
</script>
<style scoped>
.slider {
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.slider-background {
  width: 40px;
  height: 22px;
  background-color: #ddd;
  border-radius: 16px;
  transition: background-color 0.2s;
}

.slider-background.active {
  background-color: #1abc9c;
}

.slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: 1px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}

.slider-thumb.active {
  transform: translateX(18px);
}

.slider-text {
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  margin-left: 15px;
}
</style>
