<template>
  <div class="range-slider" ref="slider">
    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="modelValue"
      @input="updateValue($event.target.value)"
      @mousedown="onMouseDown($event)"
      @mousemove="onMouseMove($event)"
      @mouseup="onMouseUp($event)"
      @touchstart="onTouchStart($event)"
      @touchmove="onTouchMove($event)"
      @touchend="onTouchEnd($event)"
    />
    <div
      class="range-slider__value"
      :style="{ left: intermediateLeft - compensation + 'px' }"
    >
      {{ toRuble(intermediateValue, 0) }}
    </div>
    <div class="ragne-slider-values">
      <span>{{ toRuble(min, 0) }}</span>
      <span>{{ toRuble(max, 0) }}</span>
    </div>
  </div>
</template>

<script>
import { toRuble } from "../../utils/converters";
export default {
  name: "v-range",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    intermediateValue: {
      type: Number,
      default: 50,
    },
    step: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: [Number, String],
      default: 50,
    },
    compensation: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      isDragging: false,
      sliderWidth: 0,
      valueLeft: 0,
      intermediateLeft: 0,
    };
  },
  mounted() {
    this.sliderWidth = this.$refs.slider.offsetWidth;
    this.valueLeft = this.calculateValueLeft(+this.modelValue);
    this.intermediateLeft = this.calculateValueLeft(+this.intermediateValue);
  },
  methods: {
    toRuble,
    updateValue(newValue) {
      this.$emit("update:modelValue", newValue.toString());
      this.intermediateLeft = this.calculateValueLeft(+this.intermediateValue);
      this.valueLeft = this.calculateValueLeft(+newValue);
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.onMouseMove(event);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        const newValue = this.calculateValueFromMouseX(event.clientX);
        this.updateValue(newValue);
      }
    },
    onMouseUp() {
      this.isDragging = false;
    },
    onTouchStart(event) {
      this.isDragging = true;
      this.onTouchMove(event);
    },
    onTouchMove(event) {
      if (this.isDragging) {
        const touch = event.touches[0];
        const newValue = this.calculateValueFromMouseX(touch.clientX);
        this.updateValue(newValue);
      }
    },
    onTouchEnd() {
      this.isDragging = false;
    },
    calculateValueFromMouseX(mouseX) {
      const sliderRect = this.$refs.slider.getBoundingClientRect();
      const offsetX = mouseX - sliderRect.left;
      const ratio = offsetX / sliderRect.width;
      const range = this.max - this.min;
      const value =
        Math.round((range * ratio) / this.step) * this.step + this.min;
      return Math.max(this.min, Math.min(this.max, value));
    },
    calculateValueLeft(val) {
      const range = this.max - this.min;
      const ratio = (val - this.min) / range;
      // 15 чтобы позиционировать слайдер
      const valueLeft = ratio * this.sliderWidth;
      return valueLeft;
    },
  },
  created() {
    window.addEventListener("resize", () => {
      this.sliderWidth = this.$refs.slider.offsetWidth;
      this.intermediateLeft = this.calculateValueLeft(+this.intermediateValue);
    });
  },
};
</script>

<style lang="scss">
.range-slider {
  position: relative;
}
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  margin: 0 !important;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  border: 5px solid $accent;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: $fourth-grey;
  border-radius: 10px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $accent;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  // border-width: 24px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-upper {
  background: $fourth-grey;
  border-radius: 2.6px;
}
input[type="range"]:focus::-ms-fill-upper {
  background: $accent;
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: $fourth-grey;
  border-radius: 10px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $accent;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: $fourth-grey;
  border-radius: 10px;
}
input[type="range"]::-moz-range-thumb {
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border-radius: 2.6px;
}
input[type="range"]::-ms-thumb {
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: $fourth-grey;
}

.range-slider__value {
  position: absolute;
  text-align: center;
  bottom: 0;
  font-size: 14px;
  color: darkblue;
  // &:nth-child(4) {
  //   display: none;
  // }
  &:nth-child(3) {
    right: 0;
  }
}
.ragne-slider-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  span {
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
  }
}
</style>
