<template>
  <header class="v-header">
    <nav class="v-header__nav">
      <v-icon
        class="v-header__logo"
        @click="this.$router.push('/')"
        :icon="require(`@/assets/svg/logo.svg`)"
      />

      <div class="v-header__wrapper" v-show="!this.$route?.meta?.auth">
        <div class="v-header__links">
          <a
            href="#equipment"
            class="v-header__link"
            @click="this.$router.push({ path: '/', hash: '#equipment' })"
          >
            Об услуге
          </a>
          <a
            href="#steps"
            class="v-header__link"
            @click="this.$router.push({ path: '/', hash: '#steps' })"
          >
            Этапы
          </a>
        </div>
      </div>

      <div class="v-header__actions">
        <div class="v-header__contact">
          <a href="tel:+" class="v-header__contact-phone">
            8 (964) 989-56-92
          </a>
          <p class="v-header__contact-descr">Телефон горячей линии</p>
        </div>
        <v-btn class="v-header__auth" @click="checkLogin">
          {{
            authorized
              ? `${this.USER?.last_name} 
                ${this.USER?.first_name?.substring(0, 1)}. 
                ${this.USER?.middle_name?.substring(0, 1)}.`
              : "Войти"
          }}
          <template #prepend>
            <v-icon
              class="v-header__auth-icon"
              @click="this.$router.push('/')"
              :icon="require(`@/assets/svg/user-icon.svg`)"
            />
          </template>
        </v-btn>
        <v-btn
          class="v-header__logout"
          @click="logout"
          v-show="this.$route.meta?.auth && authorized"
        >
          <template #prepend>
            <v-icon
              class="v-header__logout-icon"
              :icon="require(`@/assets/svg/logout.svg`)"
            />
          </template>
        </v-btn>
      </div>
      <div class="v-header__contact--mobile">
        <a href="tel:+89649895692" class="v-header__contact-phone"> 8 (964) 989-56-92 </a>
        <p class="v-header__contact-descr">Телефон горячей линии</p>
      </div>
    </nav>
    <v-popup
      @closePopup="closeDialog('signin_dialog')"
      class="signin__dialog"
      :visible="signin_dialog.visible"
    >
      <template #title>
        {{ signin_dialog.title }}
      </template>
      <template #default>
        <v-input
          :attrs="signin_form.fields.phone.attrs"
          v-model="signin_form.fields.phone.value"
          class="signin__dialog-inp"
          :errorBag="signin_form.fields.phone.errorBag"
          :serverErrorBag="signin_form.fields.phone.serverErrorBag"
        />
        <v-input
          :attrs="signin_form.fields.password.attrs"
          v-model="signin_form.fields.password.value"
          class="signin__dialog-inp"
          :errorBag="signin_form.fields.password.errorBag"
          :serverErrorBag="signin_form.fields.password.serverErrorBag"
        />
        <v-btn class="signin__dialog-signin signin__dialog-btn" @click="signin">
          Войти в личный кабинет
        </v-btn>
        <v-btn
          class="signin__dialog-signup signin__dialog-btn"
          @click="openDialog('signup_dialog'), closeDialog('signin_dialog')"
        >
          Регистрация
        </v-btn>
        <p
          class="signin__dialog-reset signin__dialog-btn"
          @click="openDialog('reset_dialog'), closeDialog('signin_dialog')"
        >
          Восстановить пароль
        </p>
      </template>
    </v-popup>
    <v-popup
      @closePopup="closeDialog('signup_dialog')"
      class="signup__dialog"
      :visible="signup_dialog.visible"
    >
      <template #title>
        {{ signup_dialog.title }}
      </template>
      <template #default>
        <p class="signup__dialog-descr">
          Введите ваш номер телефона. Мы отправим смс с 6-значным паролем для
          входа в личный кабинет.
        </p>
        <v-input
          :attrs="signup_form.fields.phone.attrs"
          v-model="signup_form.fields.phone.value"
          class="signup__dialog-inp"
          :errorBag="signup_form.fields.phone.errorBag"
          :serverErrorBag="signup_form.fields.phone.serverErrorBag"
          v-show="signup_form.step == 0"
        />
        <v-input
          :attrs="signup_form.fields.password.attrs"
          v-model="signup_form.fields.password.value"
          class="signup__dialog-inp"
          :errorBag="signup_form.fields.password.errorBag"
          :serverErrorBag="signup_form.fields.password.serverErrorBag"
          v-show="signup_form.step == 1"
        />
        <v-btn
          class="signup__dialog-signin signup__dialog-btn"
          @click="signupController"
        >
          {{ signup_form.step == 0 ? "Получить пароль" : "Войти" }}
        </v-btn>
        <p
          class="signup__dialog-reset signup__dialog-btn"
          @click="backStep('signup_form')"
        >
          Вернуться назад
        </p>
      </template>
    </v-popup>
    <v-popup
      @closePopup="closeDialog('reset_dialog')"
      class="signup__dialog"
      :visible="reset_dialog.visible"
    >
      <template #title>
        {{ reset_dialog.title }}
      </template>
      <template #default>
        <p class="signup__dialog-descr">
          {{
            reset_form?.step == 0
              ? "Введите ваш номер телефона. Мы отправим смс с 6-значным паролем для входа в личный кабинет."
              : "На указанный номер телефона был отправлен смс код с паролем. Для входа в личный кабинет, выполните авторизацию."
          }}
        </p>
        <v-input
          :attrs="reset_form.fields.phone.attrs"
          v-model="reset_form.fields.phone.value"
          class="signup__dialog-inp"
          :errorBag="reset_form.fields.phone.errorBag"
          :serverErrorBag="reset_form.fields.phone.serverErrorBag"
          v-show="reset_form.step == 0"
        />
        <v-btn
          class="signup__dialog-signin signup__dialog-btn"
          @click="resetPassword"
        >
          {{
            reset_form.step == 0
              ? "Восстановить пароль"
              : "Выполнить авторизацию"
          }}
        </v-btn>
        <p
          class="signup__dialog-reset signup__dialog-btn"
          @click="backStep('reset_form')"
        >
          Вернуться назад
        </p>
      </template>
    </v-popup>
  </header>
</template>
<script>
import { required, validateMinLength } from "../../utils/validates";
import gsap from "gsap";
import { mapActions, mapGetters } from "vuex";
import { toServerPhone } from "../../utils/converters";
export default {
  name: "v-header",
  data() {
    return {
      menuToggle: false,
      signedIn: false,
      signin_dialog: {
        visible: false,
        title: "Вход в личный кабинет",
      },
      signin_form: {
        step: 0,
        fields: {
          password: {
            value: "",
            attrs: {
              id: "password",
              placeholder: "***_***",
              label: "Пароль",
              maska: {
                mask: "HHHHHHHHHHHHH",
                tokens: { H: { pattern: /[A-Z-a-z1-9 ]/ } },
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [6, 6, true],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            serverErrorBag: [],
            isDirty: false,
          },
          phone: {
            value: "",
            attrs: {
              id: "phone",
              label: "Номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            serverErrorBag: [],
            isDirty: false,
          },
        },
      },
      reset_dialog: {
        visible: false,
        title: "Восстановление пароля",
      },
      reset_form: {
        step: 0,
        fields: {
          phone: {
            value: "",
            attrs: {
              id: "phone",
              label: "Номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            serverErrorBag: [],
            isDirty: false,
          },
        },
      },
      signup_dialog: {
        visible: false,
        title: "Регистрация",
      },
      signup_form: {
        step: 0,
        fields: {
          phone: {
            value: "",
            attrs: {
              id: "phone",
              label: "Номер телефона",
              placeholder: "Введите номер",
              maska: {
                mask: "+7 (###) ###-##-##",
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            serverErrorBag: [],
            isDirty: false,
          },
          password: {
            value: "",
            attrs: {
              id: "password",
              placeholder: "***_***",
              label: "Пароль",
              maska: {
                mask: "HHHHHHHHHHHHH",
                tokens: { H: { pattern: /[A-Z-a-z1-9 ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
              validateMinLength: {
                fn: validateMinLength,
                args: [6, 6, true],
              },
            },
            errorBag: [],
            serverErrorBag: [],
            isDirty: false,
          },
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "LOGIN",
      "GET_PASS",
      "addNotification",
      "RESET_PASS",
      "LOGOUT",
      "GET_USER",
    ]),
    toggleMenu() {
      this.menuToggle = !this.menuToggle;
    },
    async logout() {
      await this.LOGOUT().then(() => {
        localStorage.removeItem("multipolis_token");
        this.$store.state.token = "";
        this.$router.push("/");
      });
    },
    initMenu() {
      const menu = this.$refs?.menu;
      const wrapper = this.$refs?.wrapper;
      const line = menu?.children[0];
      const line2 = menu?.children[1];
      const line3 = menu?.children[2];
      this.menuAnimation = gsap.timeline({ paused: true });
      this.menuAnimation.to(line2, 0.2, { opacity: 0 });
      this.menuAnimation.to(line, 0.4, { top: "50%", rotate: "45deg" });
      this.menuAnimation.to(
        line3,
        0.4,
        { top: "50%", rotate: "-45deg" },
        "-=0.4"
      );
      this.menuAnimation.to(wrapper, { x: 0 }, "-=0.4");
    },
    checkLogin() {
      if (this.TOKEN?.length > 9) this.$router.push("/lk");
      else this.openDialog("signin_dialog");
    },
    resetPassword() {
      this.reset_form.fields.phone.serverErrorBag = [];
      this.reset_form.fields.phone.validate.required.fn(
        this.reset_form.fields.phone
      );
      this.reset_form.fields.phone.validate.validateMinLength.fn(
        this.reset_form.fields.phone,
        ...this.reset_form.fields.phone.validate.validateMinLength.args
      );
      this.$nextTick(async () => {
        if (this.reset_valid && this.reset_form.step < 1) {
          await this.RESET_PASS({
            phone: toServerPhone(this.reset_form.fields.phone.value),
          }).then((res) => {
            if (res?.status == 200) this.nextStep("reset_form");
            else if (res?.status == 404)
              this.reset_form.fields.phone.serverErrorBag.push(
                res?.data?.detail
              );
            else
              this.reset_form.fields.phone.serverErrorBag.push(
                res?.data?.phone?.[0]
              );
          });
          return;
        }
        if (this.reset_form.step == 1) {
          this.closeDialog("reset_dialog");
          this.openDialog("signin_dialog");
        }
      });
    },
    signin() {
      Object.keys(this.signin_form.fields).map((item) => {
        if (this.signin_form.fields[item]?.validate) {
          Object.values(this.signin_form.fields[item].validate).map((el) => {
            el.fn(this.signin_form.fields[item], ...el.args);
          });
        }
      });

      this.$nextTick(async () => {
        this.signin_form.fields.phone.serverErrorBag = [];
        this.signin_form.fields.password.serverErrorBag = [];
        if (this.signin_valid) {
          const { phone, password } = this.signin_form.fields;
          await this.LOGIN({
            phone: toServerPhone(phone.value),
            password: password.value,
          }).then((res) => {
            if (res?.status == 200) {
              // login logic
              this.$router.push("/lk");
            }
            if (res?.status == 400)
              this.signin_form.fields.password.serverErrorBag.push(
                res?.data?.non_field_errors?.[0] || "Неизвестная ошибка"
              );
          });
          // this.closeDialog("signin_dialog")
        }
      });
    },
    signupController() {
      const form = this?.signup_form;
      console.log(form.step);
      if (form?.step == 0) {
        this.getPass();
        return;
      }
      if (form?.step == 1) return this.setPass();
    },
    nextStep(form) {
      this[form].step++;
    },
    backStep(form) {
      console.log(form);
      const step = this?.[form]?.step;
      if (step <= 0 && form == "signup_form") {
        this.closeDialog("signup_dialog");
        this.openDialog("signin_dialog");
      } else if (step <= 0 && form == "reset_form") {
        this.closeDialog("reset_dialog");
        this.openDialog("signin_dialog");
      } else this[form].step--;
    },
    getPass() {
      this.signup_form.fields.phone.validate.required.fn(
        this.signup_form.fields.phone
      );
      this.signup_form.fields.phone.validate.validateMinLength.fn(
        this.signup_form.fields.phone,
        ...this.signup_form.fields.phone.validate.validateMinLength.args
      );
      this.$nextTick(async () => {
        this.signup_form.fields.phone.serverErrorBag = [];
        if (this.signup_phone_valid) {
          await this.GET_PASS(
            toServerPhone(this.signup_form.fields.phone.value)
          ).then((res) => {
            if (res?.status == 201) {
              this.nextStep("signup_form");
            } else
              this.signup_form.fields.phone.serverErrorBag.push(
                res?.data?.detail
              );
            // this.nextStep("signup_form");
          });
        }
      });
    },
    setPass() {
      this.signup_form.fields.password.validate.required.fn(
        this.signup_form.fields.password
      );
      this.signup_form.fields.password.validate.validateMinLength.fn(
        this.signup_form.fields.password,
        ...this.signup_form.fields.password.validate.validateMinLength.args
      );
      this.$nextTick(async () => {
        if (this.signup_password_valid) {
          const { phone, password } = this.signup_form.fields;
          await this.LOGIN({
            phone: toServerPhone(phone.value),
            password: password.value,
          }).then((res) => {
            if (res?.status == 200) {
              // login logic
              this.$router.push("/lk");
            }
            // this.closeDialog("signup_dialog");
          });
        }
      });
    },
    openDialog(dialog) {
      if (dialog == "signin_dialog" && this.authorized) this.$router.push("/");
      else this[dialog].visible = true;
    },
    closeDialog(dialog) {
      this[dialog].visible = false;
    },
  },
  computed: {
    ...mapGetters(["TOKEN", "USER"]),
    signup_phone_valid() {
      const phone = this.signup_form.fields.phone;
      return !phone.errorBag.length && phone.isDirty ? true : false;
    },
    signup_password_valid() {
      const password = this.signup_form.fields.password;
      return !password.errorBag.length && password.isDirty ? true : false;
    },
    reset_valid() {
      const phone = this.reset_form.fields.phone;
      return !phone.errorBag.length && phone.isDirty ? true : false;
    },
    signin_valid() {
      if (
        Object.values(this.signin_form.fields).filter(
          (item) => item?.errorBag?.length || (!item?.isDirty && item?.validate)
        ).length
      ) {
        return false;
      } else return true;
    },
    authorized() {
      return this.TOKEN?.length > 10;
    },
  },
  watch: {
    menuToggle(value) {
      if (value) this.menuAnimation.play();
      else this.menuAnimation.reverse();
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      if (!this.USER?.first_name && this.authorized) await this.GET_USER();
    });
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/components/signin_dialog.scss";
@import "../../assets/styles/components/signup_dialog.scss";
.v-header {
  background-color: $white;

  /* .v-header__menu */

  &__menu {
    min-height: 12px;
    min-width: 24px;
    border-radius: 50%;
    display: none;
    position: relative;
    &::after {
      content: "";
      background-color: #f4f8f9;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 2;
      top: 50%;
      left: 50%;
    }
  }
  /* .v-header__menu-line */

  &__menu-line {
    z-index: 3;
    width: 100%;
    height: 2px;
    position: absolute;
    &:nth-child(1) {
      top: 0px;
      background-color: $accent;
      // transform-origin: center;
    }
    &:nth-child(2) {
      top: 60%;
      width: 60%;
      transform: translateY(-50%);
      background-color: $accent;
    }
    &:nth-child(3) {
      top: 100%;
      background-color: $accent;
      // transform-origin: center;
    }
  }

  /* .v-header__nav */

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    padding: 18px 10px;
  }

  /* .v-header__wrapper */

  &__wrapper {
    display: flex;
  }

  /* .v-header__links */

  &__links {
    display: flex;
    align-items: center;
  }

  /* .v-header__logo */

  &__logo {
    position: relative;
    z-index: 2;
  }

  /* .v-header__link */

  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-right: 120px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  /* .v-header__actions */

  &__actions {
    display: flex;
    align-items: center;
  }

  /* .v-header__contacts */

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 23px;
  }

  /* .v-header__contacts-title */

  &__contacts-title {
    color: $accent;
    display: none;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  /* .v-header__contact */

  &__contact {
    font-weight: 400;
    font-size: 14px;
    margin-right: 27px;
    text-align: right;
  }

  /* .v-header__contact-descr */

  &__contact-descr {
    color: $fourth-grey;
    font-size: 12px;
  }

  /* .v-header__contact--mobile */

  &__contact--mobile {
    display: none;
  }

  /* .v-header__auth */

  &__auth {
  }

  /* .v-header__auth-icon */

  &__auth-icon {
    margin-right: 10px;
  }

  /* .v-header__logout */

  &__logout {
    width: 46px;
    height: 46px;
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: $text-black;
    margin-left: 10px;
  }
  /* .v-header__lk */

  &__lk {
    display: none;
  }
  /* .v-header__btn */

  &__btn {
    margin-right: 12px;
  }
}

@media (max-width: 1200px) {
  .v-header {
    /* .v-header__logo */
    &__logo {
      margin-right: 12px;
    }
    &__link {
      margin-right: 40px;
    }
  }
}
@media (max-width: 1024px) {
  .v-header {
    /* .v-header__nav */

    &__nav {
    }

    /* .v-header__menu */

    &__menu {
      display: flex;
      margin-right: 24px;
      position: fixed;
      left: 20px;
      z-index: 6;
    }

    /* .v-header__menu-line */

    &__menu-line {
    }

    /* .v-header__logo */

    &__logo {
      align-items: flex-start;
      display: flex;
    }

    /* .v-header__links */

    &__links {
    }

    /* .v-header__link */

    &__link {
      &:last-child {
      }
    }

    /* .v-header__wrapper */

    &__wrapper {
      padding: 20px 15px;
      font-size: 16px;
    }

    /* .v-header__actions */

    &__actions {
    }

    /* .v-header__contacts */

    &__contacts {
      margin-right: 0;
      margin-bottom: 20px;
    }

    /* .v-header__contacts-title */

    &__contacts-title {
      display: initial;
    }

    /* .v-header__contact */

    &__contact {
      font-size: inherit;
    }

    /* .v-header__lk */

    &__lk {
      display: flex;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    /* .v-header__auth */

    &__auth {
    }
  }
}
@media (max-width: 768px) {
  .v-header {
    /* .v-header__nav */

    &__nav {
    }

    /* .v-header__menu */

    &__menu {
    }

    /* .v-header__menu-line */

    &__menu-line {
    }

    /* .v-header__logo */

    &__logo {
    }

    /* .v-header__wrapper */

    &__wrapper {
      display: none;
    }

    /* .v-header__links */

    &__links {
    }

    /* .v-header__link */

    &__link {
    }

    /* .v-header__actions */

    &__actions {
    }

    /* .v-header__contacts */

    &__contacts {
    }

    /* .v-header__contact */

    &__contact {
    }

    /* .v-header__lk */

    &__lk {
    }

    /* .v-header__auth */

    &__auth {
    }
  }
}
@media (max-width: 767px) {
  .v-header {
    /* .v-header__nav */

    &__nav {
    }

    /* .v-header__menu */

    &__menu {
    }

    /* .v-header__menu-line */

    &__menu-line {
    }

    /* .v-header__logo */

    &__logo {
      max-width: 160px;
      width: 100%;
    }

    /* .v-header__wrapper */

    &__wrapper {
      display: none;
    }

    /* .v-header__links */

    &__links {
    }

    /* .v-header__link */

    &__link {
    }

    /* .v-header__actions */

    &__actions {
    }

    /* .v-header__contacts */

    &__contacts {
    }

    /* .v-header__contact */

    &__contact {
      min-width: 140px;
    }

    /* .v-header__lk */

    &__lk {
    }

    /* .v-header__auth */

    &__auth {
      white-space: nowrap;
    }
  }
}
@media (max-width: 575px) {
  .v-header {
    /* .v-header__logo */

    &__logo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      svg {
        display: none;
        height: auto;
      }
    }
    /* .v-header__logout */
    &__logout {
      min-width: 46px;
      min-height: 46px;
    }
    /* .v-header__contact */
    &__contact {
    }
    /* .v-header__contact--mobile */
    &__contact--mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 10px;
    }
    /* .v-header__contact-auth */
    &__contact-auth {
      display: initial;
    }
    /* .v-header__nav */
    &__nav {
      flex-wrap: wrap;
    }
    /* .v-header__actions */
    &__actions {
      margin-top: 10px;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      order: 1;
    }
  }
}
@media (max-width: 513px) {
  .v-header {
    /* .v-header__logo */

    &__logo {
      max-width: 130px;
    }
    /* .v-header__logout */
    &__logout {
    }
    /* .v-header__contact */
    &__contact {
    }
    /* .v-header__contact--mobile */
    &__contact--mobile {
    }
    /* .v-header__contact-auth */
    &__contact-auth {
    }
    /* .v-header__nav */
    &__nav {
    }
    /* .v-header__actions */
    &__actions {
      margin-top: 10px;
      width: 100%;
    }
  }
}
@media (max-width: 320px) {
}
</style>

<style lang="scss">
@media (max-width: 575px) {
  .v-header {
    /* .v-header__logo */

    &__logo {
      svg {
        max-width: 156px;
        height: auto;
      }
    }
    /* .v-header__contact */

    &__contact {
      display: none;
    }
  }
}
.condition {
  /* .condition__dialog-subtitle */

  &__dialog-subtitle {
    font-size: 13px;
    line-height: 130% !important;
    color: $third-grey;
    margin-bottom: 15px !important;
  }

  /* .condition__dialog-btn */

  &__dialog-btn {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
