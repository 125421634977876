<template>
  <label
    class="v-input__label"
    :for="attrs.id"
    @focusout="focusOutHandler"
    tabindex="-1"
  >
    <p class="v-input__label-text" v-if="attrs.label">
      {{ attrs.label }}
      <slot name="label_append"></slot>
    </p>

    <div class="v-input__wrapper">
      <div
        class="v-input__wrapper-container"
        :class="{
          'input--error': errorBag.length > 0 || serverErrorBag.length > 0,
        }"
      >
        <input
          tabindex="0"
          class="v-input__wrapper-input"
          :class="attrs.id"
          :placeholder="attrs.placeholder"
          :style="{
            'max-width': attrs.width + 'px',
            height: attrs.height + 'px',
          }"
          :id="attrs.id"
          @input="updateModelValue($event.target.value)"
          :maxlength="attrs.v_maxlength"
          @focus="focusHandler"
          :value="modelValue"
          :disabled="attrs.disabled"
          v-maska="attrs?.maska"
          :type="attrs?.type"
        />
        <div
          v-show="this.focus && modelValue?.length > 1 && suggestions?.length"
          class="custom-input-suggestions"
        >
          <!-- <slot name="suggestions"></slot> -->
          <div
            @click.self="selectSuggest($event, suggest)"
            v-for="(suggest, idx) in this.suggestions"
            :key="idx"
            class="custom-input-suggestions-item"
          >
            {{ suggest.value }}
          </div>
        </div>
        <!-- v-regex:[attrs.regex]="modelValue" -->
        <!-- <v-icon
          class="v-input__eye"
          v-if="attrs?.always_type == 'password'"
          :name="attrs?.type == 'password' ? 'eye_unactive' : 'eye_active'"
          @click="togglePwd"
          width="19"
          height="19"
        /> -->
        <!-- <v-icon
          class="v-input__clear"
          :class="{ 'v-input__clear--error': errorBag.length }"
          :icon="require(`@/assets/svg/input_clear_icon.svg`)"
          @click="clearInput"
          v-show="focus && clear"
        /> -->
      </div>
      <p class="v-input__error" ref="errors" v-show="errorText">
        {{ errorBag?.[0] }}
      </p>
      <p class="v-input__error v-input__serverError" ref="serverErrors">
        {{ serverErrorBag?.[0] }}
      </p>
      <slot name="inner"></slot>
    </div>
  </label>
</template>
<script>
import gsap from "gsap";

export default {
  name: "v-input",
  props: {
    modelValue: { type: String, default: null },
    attrs: {
      type: Object,
      default: {},
    },
    errorBag: {
      type: Array,
      default: [],
    },
    serverErrorBag: {
      type: Array,
      default: [],
    },
    suggestions: {
      type: Array,
      default: [],
    },
    clear: {
      type: Boolean,
      default: true,
    },
    errorText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      focus: false,
      config: {
        attributes: true,
        childList: true,
        subtree: true,
      },
      observer: new MutationObserver(this.callback),
    };
  },
  methods: {
    selectSuggest(evt, suggest) {
      console.log(evt);
      this.$emit("selectSuggest", { field: this, suggest });
    },
    callback(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          gsap.fromTo(
            this.$refs.errors,
            { opacity: 0, transition: 1000 },
            { opacity: 1, transition: 1000 }
          );
        } else if (mutation.type === "attributes") {
        }
      }
    },
    clearInput() {
      this.$emit("update:modelValue", "");
      this.$el.control.value = "";
    },
    focusOutHandler() {
      setTimeout(() => {
        this.focus = false;
      }, 100);
    },
    focusHandler() {
      this.focus = true;
    },
    updateModelValue(val) {
      this.$emit("update:modelValue", val);
    },
    togglePwd(e) {
      e.preventDefault();
      this.$emit("togglePwd", this.attrs.id);
    },
    // selectSuggestions(suggest, field) {
    //   if ((field = "department-code")) {
    //     this.$emit("update:modelValue", suggest.data.code);
    //   }
    // },
  },
  mounted() {
    this.observer.observe(this.$refs.errors, this.config);
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.v-input {
  // .v-input__label

  &__label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
  }

  // .v-input__label-text

  &__label-text {
    font-size: 13px;
    color: $text-black;
    margin-bottom: 5px;
    font-family: "Rubik", sans-serif;
    display: flex;
    align-items: center;
  }

  // .v-input__wrapper

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  // .v-input__wrapper-container

  &__wrapper-container {
    // min-width: 280px;
    border-radius: 10px;
    width: 100%;
    position: relative;
  }

  // .v-input__clear

  &__clear {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
  }

  // .v-input__wrapper-input

  &__wrapper-input {
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid $grey;
    outline: none;
    padding: 16px 15px;
    line-height: 19px;
    width: 100%;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    &::placeholder {
      color: $grey;
      font-size: 12px;
    }
  }

  // .v-input__eye

  &__eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  // .v-input__error

  &__error {
    font-family: "Rubik";
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    max-width: 100%;
    white-space: break-spaces;
    span,
    a {
      color: inherit;
      border-bottom: 1px solid $red;
      padding-bottom: 1px;
    }
  }
}
.custom-input-suggestions {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: $sec-grey;
  z-index: 2;
  width: 100%;
  max-height: 300px;
  font-size: 16px;
  border-radius: 10px;
  overflow-y: auto;
  padding: 5px 0;
  font-family: "Lato", sans-serif;
  //   &::-webkit-scrollbar {
  //   width: 5px;
  //   background-color: $secondRed;
  // }
  // &::-webkit-scrollbar-track {
  //   background: $thirdGray;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: $seventhGray;
  //   border: 3px solid $secondRed;
  // }
}
.custom-input-suggestions::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  background-color: red;
}
.custom-input-suggestions::-webkit-scrollbar-track {
  background: $grey;
}
.custom-input-suggestions::-webkit-scrollbar-thumb {
  background-color: $text-black;
}
.custom-input-suggestions-item {
  padding: 6px 15px;
  font-size: inherit;
  line-height: 130%;
  text-align: left;
  transition: 0.3s ease;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #838383;
  }
}
.v-inputerror {
}
.input--error {
  border: 1px solid $red !important;
}

@media (max-width: 1600px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
}
</style>
