import axios from "axios";
import store from "../store/index";
import router from "../router/index";

export default {
  async function(method, url, mutations, params, payload, headers = {}) {
    return await axios({
      method,
      params,
      headers,
      data: payload,
      url: process.env.VUE_APP_API_URL + url,
    })
      .then((res) => {
        console.log(res);
        if (mutations?.length) {
          console.log(mutations);
          for (const func of mutations) {
            store.commit(func, res);
          }
          console.log(res);
          return res;
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == 401) {
          store.dispatch("addNotification", {
            message: "Токен доступа истёк, пройдите авторизацию повторно",
          });
          store.state.token = "";
          localStorage.removeItem("multipolis_token");
          router.push("/");
        }
        return error?.response;
        // console.log(error.text);
        // if (status == 401) {
        //   store.dispatch("LOGOUT");
        //   store.state.global_notifications.notification =
        //     "Токен истёк или не валиден, пожалуйста пройдите авторизацию";
        //   store.commit("SET_GLOBAL_NOTIFICATION");
        //   return;
        // }
      });
  },
};
