<template>
  <footer class="v-footer">
    <div class="v-footer__container">
      <v-icon
        class="v-footer__logo"
        @click="this.$router.push('/')"
        :icon="require(`@/assets/svg/white-logo.svg`)"
      />
      <div class="v-footer__links">
        <a
          href="#equipment"
          class="v-footer__link"
          @click="this.$router.push({ path: '/', hash: '#equipment' })"
          >Об услуге</a
        >
        <a
          href="#steps"
          class="v-footer__link"
          @click="this.$router.push({ path: '/', hash: '#steps' })"
          >Этапы</a
        >
      </div>
      <div class="v-footer__contact">
        <a href="tel:+89649895692" class="v-footer__contact-phone">
          8 (964) 989-56-92
        </a>
        <p class="v-footer__contact-descr">Телефон горячей линии</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { required, validateMinLength } from "../../utils/validates";
import { toServerPhone } from "../../utils/converters";
import { mapActions } from "vuex";
export default {
  name: "v-footer",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["addNotification"]),
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.v-footer {
  background-color: $text-black;
  /* .v-footer__container */

  &__container {
    max-width: $container;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: $white;
    padding: 27px 10px;
  }

  /* .v-footer__logo */

  &__logo {
  }

  /* .v-footer__links */

  &__links {
  }

  /* .v-footer__link */

  &__link {
    font-size: 14px;
    color: inherit;
    margin-right: 80px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  /* .v-footer__contact */

  &__contact {
    text-align: right;
  }

  /* .v-footer__contact-phone */

  &__contact-phone {
    color: inherit;
  }

  /* .v-footer__contact-descr */

  &__contact-descr {
  }
}

@media (max-width: 768px) {
  .v-footer {
    /* .v-footer__container */

    &__container {
      padding: 40px 10px;
    }

    /* .v-footer__logo */

    &__logo {
    }

    /* .v-footer__links */

    &__links {
      order: 1;
      width: 100%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-top: 40px;
    }

    /* .v-footer__link */

    &__link {
    }

    /* .v-footer__contact */

    &__contact {
    }

    /* .v-footer__contact-phone */

    &__contact-phone {
    }

    /* .v-footer__contact-descr */

    &__contact-descr {
    }
  }
}
@media (max-width: 420px) {
  .v-footer {
    /* .v-footer__container */

    &__container {
      flex-direction: column;
    }

    /* .v-footer__logo */

    &__logo {
    }

    /* .v-footer__links */

    &__links {
      margin-top: 0px;
    }

    /* .v-footer__link */

    &__link {
    }

    /* .v-footer__contact */

    &__contact {
      text-align: center;
      margin: 30px 0;
    }

    /* .v-footer__contact-phone */

    &__contact-phone {
    }

    /* .v-footer__contact-descr */

    &__contact-descr {
    }
  }
}
</style>