import apiRequest from "../../middlewares/api-request";
const signin = {
  state: {},
  mutations: {},
  actions: {
    async LOGIN({ commit }, payload) {
      const res = await apiRequest.function(
        "POST",
        `auth/token/login/`,
        null,
        null,
        payload
      );
      if (res?.status == 200) commit("SET_TOKEN", res?.data?.auth_token);
      return res;
    },
    async LOGOUT() {
      const res = await apiRequest.function(
        "POST",
        `auth/token/logout/`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      return res;
    },
    async RESET_PASS({ commit }, payload) {
      const res = await apiRequest.function(
        "PATCH",
        `/reset-password/`,
        null,
        null,
        payload
      );
      return res;
    },
  },
  getters: {},
};

export default signin;
