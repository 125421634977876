import apiRequest from "../../middlewares/api-request";
const dashboard = {
  state: {
    service_list: [],
    policies_list: [],
    user: {},
  },
  mutations: {
    SET_SERVICES_LIST: (state, list) => {
      list.map((item) => {
        item.id += "";
      });
      state.service_list = list;
    },
    SET_POLICIES_LIST: (state, list) => {
      list.map((item) => {
        item.service_value = "";
        item.services[0].id = item.services?.[0]?.id + "";
        // if (item?.status == "Новый") item.services = state.service_list;
        if (item?.expiration_date !== null) {
          const [year, month, day] = item?.expiration_date?.split(".");
          item.expiration_date = `${day}.${month}.${year?.slice(-2)}`;
        }
      });
      const newArr = list.reverse();
      console.log(newArr);
      state.policies_list = newArr;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    async GET_SERVICES_LIST({ commit }) {
      const res = await apiRequest.function(
        "GET",
        `api/v1/services/`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      if (res?.status == 200) commit("SET_SERVICES_LIST", res?.data);
      return res;
    },
    async GET_POLICIES_LIST({ commit }) {
      const res = await apiRequest.function(
        "GET",
        `api/v1/client/policies/`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      if (res?.status == 200) commit("SET_POLICIES_LIST", res?.data?.policies);
      return res;
    },
    async ACTIVATE_POLICY({ commit }, payload) {
      const res = await apiRequest.function(
        "PATCH",
        `api/v1/client/policy/activate/`,
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      return res;
    },
    async GET_USER({ commit }) {
      const res = await apiRequest.function(
        "GET",
        `api/v1/client/`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      if (res?.status == 200) commit("SET_USER", res?.data);
      return res;
    },
  },
  getters: {
    SERVICES(state) {
      return state.service_list;
    },
    POLICIES(state) {
      return state.policies_list;
    },
    USER(state) {
      return state.user;
    },
  },
};

export default dashboard;
