import { createStore } from "vuex";
import apiRequest from "../middlewares/api-request";
import signup from "./modules/signup";
import signin from "./modules/signin";
import dashboard from "./modules/dashboard";
import notifications from "./modules/notifications";

export default createStore({
  state: {
    moratorium: false,
    token: "",
  },
  getters: {
    TOKEN: (state) => {
      return state.token;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      localStorage.setItem("multipolis_token", token);
      state.token = token;
    },
  },
  actions: {},
  modules: { signup, dashboard, notifications, signin },
});
