import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "default",
      title: "Мультиполис - Главная",
      auth: false,
      name: "Главная",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/lk",
    name: "lk",
    meta: {
      layout: "lk",
      title: "Мультиполис - Личный кабинет",
      auth: true,
      name: "lk",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/LkView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth);
  const token =
    store.getters.TOKEN || localStorage.getItem("multipolis_token") || null;
  console.log(token, requireAuth);
  if (requireAuth) {
    if (token?.length < 8) {
      store.dispatch("addNotification", {
        message: "Токен доступа истёк, пройдите авторизацию повторно",
      });
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
